var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.pathname === '' ? 'contact' : 'contact no_spacing'},[_c('div',{staticClass:"contact__container"},[_c('div',{staticClass:"contact__wrapper"},[(_vm.pathname === '')?_c('div',{staticClass:"contact__block"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('span',[_vm._v(_vm._s(_vm.sub.span)+" "),_c('b',[_vm._v(_vm._s(_vm.sub.b))]),_c('br'),_vm._v(_vm._s(_vm.sub.p)+" ")])]):_vm._e(),_c('div',{staticClass:"contact__form"},[_c('form',{ref:"entryForm",staticClass:"form_container",on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"input-data"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],ref:"nameInput",class:{
                  validInput: _vm.isValidName,
                  errorBorder: _vm.errors.name,
                },attrs:{"type":"text"},domProps:{"value":(_vm.name)},on:{"input":[function($event){if($event.target.composing)return;_vm.name=$event.target.value},_vm.checkFilled],"blur":function($event){return _vm.onBlur('name')},"focus":function($event){return _vm.onFocus('name')}}}),_c('label',{class:{ validLabel: _vm.isValidName }},[_vm._v(_vm._s(_vm.dataFormMail.name))]),(_vm.errors.name)?_c('p',{staticClass:"danger"},[_vm._v(_vm._s(_vm.errors.name))]):_vm._e()])]),_c('div',{staticClass:"group-row"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"input-data"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],ref:"phoneInput",class:{
                    validInput: _vm.isValidPhone,
                    errorBorder: _vm.errors.phone,
                  },attrs:{"type":"tel","pattern":"\\d*","oninput":"this.value = this.value.replace(/[^0-9]/g, '');"},domProps:{"value":(_vm.phone)},on:{"input":[function($event){if($event.target.composing)return;_vm.phone=$event.target.value},_vm.checkFilled],"blur":function($event){return _vm.onBlur('phone')},"focus":function($event){return _vm.onFocus('phone')}}}),_c('label',{class:{ validLabel: _vm.isValidPhone }},[_vm._v(_vm._s(_vm.dataFormMail.phone))]),(_vm.errors.phone)?_c('p',{staticClass:"danger"},[_vm._v(_vm._s(_vm.errors.phone))]):_vm._e()])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"input-data"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"emailInput",class:{
                    validInput: _vm.isValidEmail,
                    errorBorder: _vm.errors.email,
                  },domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.email=$event.target.value},_vm.checkFilled],"blur":function($event){return _vm.onBlur('email')},"focus":function($event){return _vm.onFocus('email')}}}),_c('label',{class:{ validLabel: _vm.isValidEmail }},[_vm._v(_vm._s(_vm.dataFormMail.email))]),(_vm.errors.email)?_c('p',{staticClass:"danger"},[_vm._v(_vm._s(_vm.errors.email))]):_vm._e()])])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"textarea",class:{ filled: _vm.isFilled }},[_c('label',[_vm._v(_vm._s(_vm.dataFormMail.message))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.yourMessage),expression:"yourMessage"}],ref:"messInput",attrs:{"rows":"4","cols":"50","type":"text"},domProps:{"value":(_vm.yourMessage)},on:{"input":[function($event){if($event.target.composing)return;_vm.yourMessage=$event.target.value},_vm.checkFilled]}})])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"input-data"},[_c('div',{staticClass:"btn-cv",on:{"click":_vm.onButtonClick}},[_vm._v(" "+_vm._s(this.loadingUpload ? 'Loading...' : this.files ? this.files.name.length > 9 ? this.files.name.slice(0, 10) + '...' : this.files.name : this.upload)+" ")]),(this.files)?_c('div',{staticClass:"btn-close",on:{"click":_vm.clearFiles}},[_vm._v(" + ")]):_vm._e(),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":".pdf, .doc, .docx, .jpg, .png, .zip"},domProps:{"value":null},on:{"change":_vm.onFileChanged}}),(_vm.errors.files)?_c('p',{staticClass:"danger"},[_vm._v(" "+_vm._s(_vm.errors.files)+" ")]):_vm._e()])]),_c('div',{staticClass:"content-file-contactus mt-1"},[_vm._v(" "+_vm._s(_vm.dataFormMail.content_cv)+" ")]),_c('div',{staticClass:"form-row submit-btn"},[_c('button',{staticClass:"btn-submit",attrs:{"disabled":_vm.loadingSubmit,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.loadingSubmit ? 'Loading' : _vm.dataFormMail.submit)+" ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }