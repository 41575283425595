<!-- eslint-disable no-useless-escape -->
<template>
  <div :class="pathname === '' ? 'contact' : 'contact no_spacing'">
    <div class="contact__container">
      <div class="contact__wrapper">
        <div v-if="pathname === ''" class="contact__block">
          <h2>{{ title }}</h2>
          <span
            >{{ sub.span }} <b>{{ sub.b }}</b
            ><br />{{ sub.p }}
          </span>
        </div>
        <!-- <div class="contact__project">
          <div
            v-for="(tab, indexTab) in Object.keys(categories)"
            :key="indexTab"
            class="slide-from-right"
          >
            <router-link
              :to="`/projects?category=${encodeURIComponent(
                categories[tab].category.replace(/\s/g, '')
              )}`"
              style="text-decoration: none"
            >
              <button
                type="button"
                class="btn btn-primary text see-all d-flex align-items-center"
              >
                <span>{{ categories[tab].category }}</span>
              </button>
            </router-link>
          </div>
        </div> -->
        <div class="contact__form">
          <form
            ref="entryForm"
            class="form_container"
            @submit.prevent="sendEmail"
          >
            <div class="form-row">
              <div class="input-data">
                <input
                  ref="nameInput"
                  type="text"
                  v-model="name"
                  @input="checkFilled"
                  @blur="onBlur('name')"
                  @focus="onFocus('name')"
                  :class="{
                    validInput: isValidName,
                    errorBorder: errors.name,
                  }"
                />
                <label :class="{ validLabel: isValidName }">{{
                  dataFormMail.name
                }}</label>
                <p class="danger" v-if="errors.name">{{ errors.name }}</p>
              </div>
            </div>
            <div class="group-row">
              <div class="form-row">
                <div class="input-data">
                  <input
                    ref="phoneInput"
                    type="tel"
                    v-model="phone"
                    @input="checkFilled"
                    @blur="onBlur('phone')"
                    @focus="onFocus('phone')"
                    pattern="\d*"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '');"
                    :class="{
                      validInput: isValidPhone,
                      errorBorder: errors.phone,
                    }"
                  />
                  <label :class="{ validLabel: isValidPhone }">{{
                    dataFormMail.phone
                  }}</label>
                  <p class="danger" v-if="errors.phone">{{ errors.phone }}</p>
                </div>
              </div>
              <div class="form-row">
                <div class="input-data">
                  <input
                    ref="emailInput"
                    v-model="email"
                    @input="checkFilled"
                    @blur="onBlur('email')"
                    @focus="onFocus('email')"
                    :class="{
                      validInput: isValidEmail,
                      errorBorder: errors.email,
                    }"
                  />
                  <label :class="{ validLabel: isValidEmail }">{{
                    dataFormMail.email
                  }}</label>
                  <p class="danger" v-if="errors.email">{{ errors.email }}</p>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="textarea" :class="{ filled: isFilled }">
                <label>{{ dataFormMail.message }}</label>
                <textarea
                  ref="messInput"
                  rows="4"
                  cols="50"
                  type="text"
                  v-model="yourMessage"
                  @input="checkFilled"
                ></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="input-data">
                <div class="btn-cv" @click="onButtonClick">
                  {{
                    this.loadingUpload
                      ? 'Loading...'
                      : this.files
                      ? this.files.name.length > 9
                        ? this.files.name.slice(0, 10) + '...'
                        : this.files.name
                      : this.upload
                  }}
                </div>
                <div v-if="this.files" class="btn-close" @click="clearFiles">
                  +
                </div>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=".pdf, .doc, .docx, .jpg, .png, .zip"
                  @change="onFileChanged"
                  :value="null"
                />
                <p class="danger" v-if="errors.files">
                  {{ errors.files }}
                </p>
              </div>
            </div>
            <div class="content-file-contactus mt-1">
              {{ dataFormMail.content_cv }}
            </div>
            <div class="form-row submit-btn">
              <button
                :disabled="loadingSubmit"
                type="submit"
                class="btn-submit"
              >
                {{ loadingSubmit ? 'Loading' : dataFormMail.submit }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import emailjs from 'emailjs-com';
import Vue from 'vue';
import { mapState } from 'vuex';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

Vue.use(Toast, {
  timeout: 1000,
});

export default {
  name: 'Contact',
  data() {
    return {
      pathname: this.$route.path.split('/')[1],
      title: this.$t('home').contact.title,
      sub: this.$t('home').contact.sub,
      project: this.$t('project_detail'),
      contact: this.$t('contact'),
      button: this.$t('about_us').welcome.btn,
      service: this.$t('home').service,
      dataFormMail: this.$t('contact').dataFormMail,
      model: '',
      categories: {},
      currentTab: [],
      name: '',
      email: '',
      phone: '',
      text: '',
      yourMessage: '',
      isFormat: false,
      isFilled: false,
      isValidName: false,
      isValidEmail: false,
      isValidPhone: false,
      isCloseIcon: false,
      files: null,
      loadingSubmit: false,
      loadingUpload: false,
      // disabledSubmit: false,
      upload: this.$t('contact').dataFormMail.upload_cv,
      errors: {},
      touched: {
        name: false,
        email: false,
        phone: false,
      },
      touchedAll: false,
      nameRules: [],
      emailRules: [],
      phoneRules: [],
      imageURL: '',
    };
  },
  watch: {
    lang() {
      this.title = this.$t('home').contact.title;
      this.sub = this.$t('home').contact.sub;
      this.dataFormMail = this.$t('contact').dataFormMail;
      this.button = this.$t('about_us').welcome.btn;
      this.service = this.$t('home').service;
      this.upload = this.$t('contact').dataFormMail.upload_cv;
      this.categories = this.service.cards.map((item) => {
        return item;
      });
      this.errors = {};
      this.touched = {
        name: false,
        email: false,
        phone: false,
      };
    },
    name() {
      this.nameRules = [];
    },
    phone() {
      this.phoneRules = [];
    },
    email() {
      this.emailRules = [];
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  methods: {
    async sendEmail() {
      this.touchedAll = true;
      this.isFormat = false;
      this.loadingSubmit = true;

      this.checkValidate();

      if (Object.values(this.errors).length > 0) {
        this.loadingSubmit = false;
        return;
      }

      if (this.name && this.email && this.phone) {
        let db = firebase.firestore();
        const docRef = await db.collection('mail');
        await docRef.add({
          name: this.name,
          email: this.email,
          message: this.yourMessage,
          phone: this.phone,
          image: this.imageURL,
        });
        await emailjs
          .send(
            'service_zoomdzz',
            'template_yttaxpd',
            {
              name: this.name,
              email: this.email,
              message: this.yourMessage,
              phone: this.phone,
              files: this.imageURL,
            },
            'user_GkuFGEJa8nHdUMxMpgGa9'
          )
          .then(
            (response) => {
              if (response.text === 'OK') {
                this.$toast.success(this.dataFormMail.success, {
                  timeout: 1000,
                  position: 'bottom-right',
                });
                this.name = '';
                this.email = '';
                this.phone = '';
                this.yourMessage = '';
                this.files = null;
                this.errors = {};
                this.touched.name = false;
                this.touched.email = false;
                this.touched.phone = false;
                this.isFilled = false;
                this.checkFilled();
              } else
                this.$toast.error('FAIL', {
                  timeout: 1000,
                  position: 'bottom-right',
                });
            },
            function (error) {
              this.$toast.error('FAIL', {
                timeout: 1000,
                position: 'bottom-right',
              });
              console.log('FAILED...', error);
            }
          );
      }

      this.loadingSubmit = false;
    },
    onBlur(fieldName) {
      this.touched[fieldName] = true;
      this.touchedAll && this.checkValidate();
    },
    onFocus(fieldName) {
      this.touched[fieldName] = false;
    },
    checkValidate() {
      this.errors = {};
      if (!this.name) {
        this.errors.name = this.dataFormMail.required.name;
      } else if (
        !/^[a-zàáảãạăắằẳẵặâấầẩẫậèéẻẽẹêềếểễệđìíỉĩịòóỏõọôốồổỗộơớờởỡợùúủũụưứừửữựỳýỷỹỵ\s]+$/i.test(
          this.name
        )
      ) {
        this.errors.name = this.dataFormMail.required.error_name;
      }
      if (!this.email) {
        this.errors.email = this.dataFormMail.required.email;
        // eslint-disable-next-line no-useless-escape
      } else if (
        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)
      ) {
        this.errors.email = this.dataFormMail.required.error_email;
      }

      if (!this.phone) {
        this.errors.phone = this.dataFormMail.required.phone;
      } else if (!/^\d{10,12}$/.test(this.phone)) {
        this.errors.phone = this.dataFormMail.required.error_number;
      }
    },
    changeTab(model) {
      this.model = model;
      this.currentTab = this.categories[model];
      this.getTitle(model);
      const newQuery = { category: model };
      this.$route.replace({ path: this.$route.path, query: newQuery });
    },
    getTitle(category) {
      switch (category) {
        case 'AI':
          this.header = {
            title: this.service.cards[0].category,
            subtitle: this.service.cards[0].content,
          };
          break;
        case 'Metaverse':
          this.header = {
            title: this.service.cards[1].category,
            subtitle: this.service.cards[1].content,
          };
          break;
        case 'BIM/CAD':
          this.header = {
            title: this.service.cards[2].category,
            subtitle: this.service.cards[2].content,
          };
          break;
        case 'WebSystem':
          this.header = {
            title: this.service.cards[3].category,
            subtitle: this.service.cards[3].content,
          };
          break;
        case 'SmartphoneApp':
          this.header = {
            title: this.service.cards[4].category,
            subtitle: this.service.cards[4].content,
          };
          break;
        case 'PCApplication':
          this.header = {
            title: this.service.cards[5].category,
            subtitle: this.service.cards[5].content,
          };
          break;
      }
    },
    checkFilled() {
      this.isFilled = this.yourMessage.length > 0;
      this.isValidName = this.name.length > 0;
      this.isValidEmail = this.email.length > 0;
      this.isValidPhone = this.phone.length > 0;
    },
    onButtonClick() {
      if (this.isCloseIcon) return;
      this.$refs.uploader.click();
    },
    checkFileSize(file) {
      const maxSize = 10 * 1024 * 1024;
      return file.size < maxSize;
    },
    async onFileChanged(e) {
      this.files = e.target.files[0];
      if (!this.files) return;
      if (!this.checkFileSize(this.files)) {
        this.files = null;
        this.$toast.error(this.dataFormMail.file_size, {
          timeout: 3000,
          position: 'bottom-right',
        });
        return;
      }
      this.loadingUpload = true;

      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(this.files.name);

      try {
        await fileRef.put(this.files);
        this.imageURL = await fileRef.getDownloadURL();
        this.loadingUpload = false;
        this.$toast.success(this.dataFormMail.file_success, {
          timeout: 1000,
          position: 'bottom-right',
        });
      } catch (error) {
        this.loadingUpload = false;
        this.$toast.error('Error uploading file', {
          timeout: 3000,
          position: 'bottom-right',
        });
      }
    },
    checkFile(file) {
      const format = ['.pdf', '.doc', '.docx', '.jpg', '.png', '.zip'];
      for (let i = 0; i < format.length; i++) {
        if (file.name.toLowerCase().indexOf(format[i]) > -1) {
          return true;
        }
      }
      return false;
    },
    onCloseIcon() {
      this.isCloseIcon = true;
      this.files = null;
      setTimeout(() => {
        this.isCloseIcon = false;
      }, 500);
    },
    clearFiles() {
      this.files = null;
      this.$refs.uploader.value = null;
    },
  },
  mounted() {
    const firebaseConfig = {
      apiKey: 'AIzaSyDvF206pThe30VaIou_Cc2YLiHh-GzHYIY',
      authDomain: 'tgl-solution-product.firebaseapp.com',
      projectId: 'tgl-solution-product',
      storageBucket: 'tgl-solution-product.appspot.com',
      messagingSenderId: '671677517840',
      appId: '1:671677517840:web:8b5e5e5b13a3d51b0ffa95',
      measurementId: 'G-W0LGN0XYQN',
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app();
    }

    this.categories = this.service.cards.map((item) => {
      return item;
    });
  },
};
</script>

<style lang="scss" scroped>
img {
  object-fit: cover;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.contact {
  padding: 50px 70px;
  // background: url('/images/bg/bg-contact.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  /* background: #ffd396; */

  .contact__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 56%;
    margin: auto;
    background: #ffffff;
    /* box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1); */
    /* border-radius: 10px; */
    /* border: 3px solid var(--orange); */
    padding-bottom: 70px;
    overflow: hidden;
  }
  .contact__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #ffffff;
  }
  .contact__block {
    flex: 1;
    h2 {
      position: relative;
      z-index: 0;
      font-weight: 700;
      font-size: 40px;
      line-height: 50px;
      color: var(--blue-text);
      margin-bottom: 16px;
      text-align: center;
    }
    span {
      font-size: 20px;
      font-weight: 500;
      color: var(--grey-text);
    }
  }

  .contact__project {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 13px;

    .btn-primary {
      background: #fff;
      border-radius: 40px;
      padding: 14px 20px;
      width: max-content;
      border: 1px solid #c6c6c6;
      gap: 10px;
      margin-top: 35px;
      color: #000000;
      font-size: 20px;
      font-weight: 400;
      min-width: 6em;
      justify-content: center;
    }

    .btn-primary:hover {
      border-color: var(--blue-light);
      color: var(--blue-light);
    }

    .slide-from-right {
      animation: scrollDiv 10s linear infinite;
    }

    @keyframes scrollDiv {
      0% {
        transform: translateX(250px);
        opacity: 1;
      }
      90% {
        opacity: 1;
      }
      100% {
        transform: translateX(-200px);
        opacity: 1;
      }
    }
  }

  .contact__form {
    width: -webkit-fill-available;
  }

  .contact__form form {
    display: flex;
    flex-direction: column;
    padding: 25px 0 0;
    gap: 10px;

    .form-row {
      margin: 0;
    }

    .group-row {
      display: flex;
      gap: 24px;

      .form-row {
        width: 100%;
      }
    }

    .form-row .input-data {
      width: 100%;
      height: 40px;
      margin: 30px 0;
      position: relative;
      transition: border-bottom 0.3s;

      .danger {
        color: #f14668;
        font-size: 0.75rem;
        margin-top: 0.25rem;
        display: block;
        margin-left: 20px;
      }

      label {
        position: absolute;
        top: 50%;
        transform: translateY(-80%);
        pointer-events: none;
        transition: 0.3s;
        color: #b3b3b3;
        font-size: 16px;
        font-weight: 400;
        left: 20px;
      }

      input {
        width: 100%;
        height: 50px;
        border: none;
        outline: none;
        background: transparent;
        font-size: 18px;
        font-weight: 500;
        padding: 0 35px 0 5px;
        border-bottom: 3px solid #cfcfcfff;
        padding-left: 20px;
      }

      input:focus ~ label,
      .validLabel {
        top: 0px;
        left: 20px;
      }

      input:focus,
      .validInput {
        border-bottom: 3px solid #387effff;
      }
      /* input:focus, */
      .errorBorder {
        border-bottom: 3px solid red !important;
      }
    }

    .form-row .textarea {
      width: 100%;
      margin: 30px 0;
      position: relative;

      label {
        position: absolute;
        top: 0%;
        transform: translateY(260%);
        pointer-events: none;
        transition: 0.3s;
        color: #b3b3b3;
        font-size: 16px;
        font-weight: 400;
        left: 20px;
      }

      textarea {
        resize: none;
        display: block;
        width: 100%;
        height: 100%;
        padding: 12px;
        border: none;
        outline: none;
        overflow: hidden;
        border-bottom: 3px solid #cfcfcfff;
        font-size: 18px;
        font-weight: 700;
        padding-left: 20px;
      }
    }

    .form-row .textarea:focus-within label,
    .form-row .textarea textarea:not(:placeholder-shown) + label {
      top: -80px;
      left: 20px;
    }

    .form-row .textarea.filled label,
    .form-row .textarea textarea:focus + label {
      top: -80px !important;
      left: 20px;
    }
    .form-row .textarea:focus-within textarea,
    .form-row .textarea.filled textarea:focus,
    .form-row .textarea.filled textarea:valid {
      border-bottom: 3px solid #387effff;
    }

    .btn-cv,
    .btn-submit {
      width: 100%;
      border: 1px dashed #fc7735;
      border-radius: 40px;
      padding: 17px 0 !important;
      background: transparent !important;
      color: #fc7735;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }

    .btn-cv:hover {
      opacity: 0.6;
      transition: 0.3s;
    }

    .btn-submit:hover {
      opacity: 0.8;
      transition: 0.3s;
    }

    .btn-cv:active,
    .btn-submit:active {
      box-shadow: 0 0 0 2px #fc7735;
      outline: none;
    }

    .content-file-contactus {
      margin-top: -8px;
      font-weight: 400;
      font-size: 16px;
      color: #b3b3b3;
    }

    .btn-submit {
      font-size: 20px;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
      background-color: #fc7735 !important;
      border: none;
      height: 70px !important;
      margin-top: 20px;
    }
    .btn-close {
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 32px;
      transform: rotate(45deg);
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 99px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        transition: all 0.3s linear;
      }
    }
  }
  &.no_spacing {
    padding: 0;
    .contact__container {
      width: 100%;
    }
    .contact__form form {
      padding: 0;
    }
  }
}
@media (max-width: 1024px) {
  .contact {
    padding: 0;
  }
  .contact .contact__container {
    width: 80%;
    padding: 70px 40px;
  }
  .contact .contact__wrapper {
    flex-direction: column;
    gap: 20px;
  }
  .contact__block::after {
    display: none;
  }

  @media (max-width: 768px) {
    .contact {
      .contact__container {
        width: 95%;
        padding: 50px 15px;
        & > h2 {
          font-size: 32px;
        }
      }
      .contact__form form .group-row {
        display: block;
      }
      .contact__block h2 {
        font-size: 32px;
      }
    }
  }
}
</style>
